import { API } from "@project/shared";

export const fetchAllNews = ({ queryKey }): Promise<any> => {
  const params = {};
  params["page"] = parseInt(queryKey[1]?.page) || 1;
  params["pageSize"] = parseInt(queryKey[1]?.pageSize) || 50;
  return API.get("/admin/news/all", {
    params: params,
  });
};

export const addNews = async (formData): Promise<any> => {
  return API.post("/admin/news", formData);
};

export const getNewsDetails = (id: any): Promise<any> => {
  return API.get(`/admin/news/info/${id}`);
};

export const updateNews = async ({ id, formData }): Promise<any> => {
  return API.put(`/admin/news/${id}`, formData);
};

export const deleteNews = async (id): Promise<any> => {
  return API.delete(`/admin/news/${id}`);
};
