import { API } from "@project/shared";

export const saveContents = async (formData): Promise<any> => {
  return API.post("/admin/content", formData);
};

export const editContents = async ({ id, formData }): Promise<any> => {
  return API.put(`/admin/content/${id}`, formData);
};

export const fetchContentList = ({ queryKey }): Promise<any> => {
  const params = {};
  params["page"] = parseInt(queryKey[1]?.page) || 1;
  params["pageSize"] = parseInt(queryKey[1]?.pageSize) || 50;
  return API.get("/admin/content", {
    params: params,
  });
};
export const fetchSingleContent = (id: any): Promise<any> => {
  return API.get(`/admin/content/${id}`);
};
